import React from "react"
import Layout from "../components/layout"
// import {Link} from "gatsby"



  const ContactPage = () => {
    return (
        <Layout>
            <h1>I'd love to talk! Email me at the address below</h1>
              <p>
              <a href="mailto:mehtapaydinnnn@gmail.com"> contact me</a>
              </p>
        </Layout>
    )
  }


export default ContactPage